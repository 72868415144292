import React, { useMemo } from 'react';
import { BLOCKS, Inline, INLINES, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import {
  defaultInline,
  inlinePDF,
  inlineImage,
} from '../../utils/articleUtils';
import { Link } from 'gatsby';

const FormatUrl = (data: any, slug: string) => {
  const allPages = data?.allSitePage?.edges || []
  let url = allPages.find((link: any) => link.node.context && link.node.context.slug == slug)
  return url
}

const Quote = ({ children }: any) => (
  <span className="article-quote">
    <h3>{children[0].props.children[0]}</h3>
  </span>
)

interface Props {
	data: any;
	options?:any;
}

const RichText = (props: Props) => {
  const {
    data,
		options
  } = props

  const defaultOptions: any = useMemo(
    () => ({
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node: any, children: any) => <p>{children}</p>,
        [BLOCKS.QUOTE]: (node: any, children: any) => <Quote>{children}</Quote>,
        [INLINES.HYPERLINK]: (node: any, children: any) => {
          return (
            <a className="Link" target="_blank" href={node.data.uri}>
              {children}
            </a>
          )
        },
        [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
          return inlineImage(BLOCKS.EMBEDDED_ASSET, node.data.target as Inline)
        },
        [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
          return defaultInline(
            INLINES.EMBEDDED_ENTRY,
            node.data.target as Inline,
            data.allSitePage.edges,
          )
        },
        [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
          let slug = node.data?.target?.slug;
          const url = FormatUrl(data, slug);
          return (
            <Link className="Link" to={url?.node?.path ?? slug}>
              {children}
            </Link>
          )
        },
        [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
          return inlinePDF(INLINES.ASSET_HYPERLINK, node.data.target as Inline, children)
        },
      },
      renderMark: {
        [MARKS.BOLD]: (text: string) => <strong>{text}</strong>,
      },
      renderText: (text: string) => {
        return text.split('\n').reduce((children: any, textSegment: any, index: any) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
      },
    }),
    [data],
  );

	return <React.Fragment>
		{data != null && <>{renderRichText(data, options||defaultOptions)}</>}
	</React.Fragment>;
}

export default RichText;