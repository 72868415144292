import React from "react";
import Button from "../button/Button";
import Image from "./Image";
import './teasercampaign.less';
import RichText from './RichText';
import { getPageUrlFromSlug } from 'utils/urlUtils';

interface Props {
	teaser: any;
	allPages: any[];
}

export default class TeaserCampaign extends React.Component<Props> {
	render() {
		const teaser = this.props.teaser || {};
		const allPages = this.props.allPages;
		const imageLeft = teaser.imageLeft ? ' TeaserCampaign-container--reverse' : '';
		let buttonUrl = null;
		if(teaser.button?.link!=null)
		{
			buttonUrl=getPageUrlFromSlug(allPages, teaser.button?.link?.slug);
		}
		else if(teaser.button?.externalLink!='')
		{
			buttonUrl = teaser.button?.externalLink;
		}
		return (
			<section className="Section Section--marginCampaign Page-main">
				<div className="Page-container">
					<div className="TeaserCampaign">
						<div className={"Grid marginBlg TeaserCampaign-container" + imageLeft}>
							<div className="Grid-cell u-size1of1 u-lg-size1of2">
								<div className="marginBlg TeaserCampaign-content">
									<h2>{teaser.heading}</h2>
									<RichText data={teaser.body} />
									{(teaser.button != null && buttonUrl!=null && <Button children={teaser.button?.text} href={buttonUrl} className='button button-border blue' />)}
								</div>
							</div>
							{teaser.media!=null && 
								<div className="Grid-cell u-size1of1 u-lg-size1of2">
									<Image className="marginBlg TeaserCampaign-image" image={teaser.media} />
								</div>
							}
						</div>
					</div>
				</div>
			</section>
		);
	}
}
