import React from 'react'
import './bannercampaign.less'
import Button from '../button/Button'
import Image from './Image'
import { getPageUrlFromSlug } from 'utils/urlUtils'

interface Props {
  teaser: any
  allPages: any[]
}

export default class BannerCampaign extends React.Component<Props> {
  render() {
    const teaser = this.props.teaser
    if (teaser == null) {
      return null
    }
    const color = teaser.theme?.toLowerCase()
    let buttonColor = ''
    if (color == 'blue') {
      buttonColor = 'orange'
    } else if (color == 'peach') {
      buttonColor = 'blue'
    }
    let buttonUrl = null
    if (teaser.button?.link != null) {
      buttonUrl = getPageUrlFromSlug(this.props.allPages, teaser.button?.link?.slug)
    } else if (teaser.button?.externalLink != '') {
      buttonUrl = teaser.button?.externalLink
    }
    const theme = ' BannerCampaign--color' + teaser.theme
    return (
      <section className="Section Section--marginCampaign Page-main">
        <div className="Page-container">
          <div className={'BannerCampaign' + theme}>
            <div className="BannerCampaign-text">
              <h2>{teaser.heading}</h2>
              <p>{teaser.body.body}</p>
              <ul>
                {teaser.listItems.map((item: string) => {
                  return (
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path fill="none" d="M0 0h24v24H0V0z" />
                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                      </svg>
                      {item}
                    </li>
                  )
                })}
              </ul>
              {teaser.button != null && buttonUrl != null && (
                <Button
                  children={teaser.button.text}
                  href={buttonUrl}
                  className={'button button--normal ' + buttonColor}
                />
              )}
            </div>
            {teaser.image != null && (
              <div className="BannerCampaign-image">
                <Image className="marginBlg TeaserCampaign-image" image={teaser.image} />
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}
