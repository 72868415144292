import React from 'react';
import './quotecampaign.less';

interface Props {
	teaser: any;
}

export default class QuoteCampaign extends React.Component<Props> {
	render() {
		const teaser = this.props.teaser;
		const theme = ' QuoteCampaign--color' + teaser.theme;
		return (
			<section className="Section Section--marginCampaign Page-main">
				<div className="Page-container">
					<div className={"QuoteCampaign" + theme}>
						<div className="QuoteCampaign-text">
							<span>
								{teaser.quote}
							</span>
						</div>
						<div className="QuoteCampaign-author">
							<span>
								{teaser.author}
							</span>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
