import * as React from 'react'

interface Props {
  image?: any
  className?: string
}

const Image = (props: Props) => {
  const { className, image } = props
  if (image?.fixed == null) {
    return <></>
  }
  return (
    <React.Fragment>
      <figure>
        <img className={className} src={image.fixed.src} alt={image.title} />
      </figure>
    </React.Fragment>
  )
}

export default Image
