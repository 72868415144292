import React from "react";
import Button from "../button/Button";
import './pageHeader.less';

import RichText from './RichText';
import Image from './Image';
import { getPageUrlFromSlug } from "utils/urlUtils";

interface Props {
	page: any;
	allPages: any[];
}

export default class PageHeader extends React.Component<Props> {
	page = this.props.page;
	allPages = this.props.allPages;

	render() {
		const headerStyle = this.page.expressiveHeader ? 'PageHeader--classic' : 'PageHeader--expressive';
		const boxSize = this.page.expressiveHeader ? 'u-lg-size1of2' : 'u-size1of1';
		const theme = ' PageHeader--color' + this.page.theme;

		const button = this.page.button;
		let buttonUrl = null;
		if(button?.link!=null)
		{
			buttonUrl=getPageUrlFromSlug(this.props.allPages, button?.link?.slug);
		}
		else if(button?.externalLink!='')
		{
			buttonUrl = button?.externalLink;
		}
		return (
			<section>
				<div className={"PageHeader " + headerStyle + theme}>
					<div className="Page-container">
						<div className="Grid">
							<div className={"Grid-cell " + boxSize}>
								<div className="PageHeader-content">
									<h1 className="Heading Heading--h1">{this.page.heading}</h1>
									<p>
										<RichText data={this.page.body} />
									</p>
								</div>
								{(button != null && buttonUrl!=null && <Button children={button.text} href={buttonUrl} className='button button--normal orange' />)}
							</div>
							<div className={"Grid-cell " + boxSize}>
								<div className="PageHeader-media">
									<Image image={this.page.image} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}
